'use client';

import { memo, useEffect, useState } from 'react';
import { LoadingSpinner } from '../loading-spinner';
import { TableRowProps } from './table-row';
import { TableRowGroup } from './table-row-group';

type TableRowExpanderProps<T> = TableRowProps<T>;

function _TableRowExpander<T>(props: TableRowExpanderProps<T>) {
  const {
    columns,
    expandable,
    expandableData,
    expandedRowClassName,
    rowData,
    rowIndex,
    ...rowGroupProps
  } = props;
  const [expandedData, setExpandedData] = useState<T[] | null>(null);
  const [isFetchingExpandedData, setIsFetchingExpandedData] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (expandableData) {
      (async function () {
        setError(null);
        setIsFetchingExpandedData(true);

        try {
          const result = await expandableData({
            rowData,
            rowIndex,
          });
          setExpandedData(result);
          setIsFetchingExpandedData(false);
          setError(null);
        } catch (e) {
          setError('message' in (e as any) ? (e as Error).message : String(e));
        }
      })();
    }
  }, [expandableData, rowData, rowIndex]);

  return isFetchingExpandedData || error ? (
    <tr className={expandedRowClassName}>
      <td />
      <td colSpan={columns.length}>
        {error ? `There was an issue loading this data: ${error}` : <LoadingSpinner />}
      </td>
    </tr>
  ) : (
    <TableRowGroup<T>
      columns={columns}
      data={expandedData}
      isExpandedRowGroup
      rowClassName={expandedRowClassName}
      {...rowGroupProps}
    />
  );
}

export const TableRowExpander = memo(_TableRowExpander) as typeof _TableRowExpander;
