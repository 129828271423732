'use client';

import { memo } from 'react';
import { TableCellOptions, TableProps } from './types';
import { getCellClassName } from './util';
import { cn } from '@spikemark/ui/utils/cn';

export type TableCellProps<T> = TableCellOptions<T> &
  Pick<TableProps<T>, 'className' | 'stickyLeftClassName' | 'compact'> & {
    isFirstColumn?: boolean;
    isLastColumn?: boolean;
    rowSpan?: number;
  };

function _TableCell<T>(props: TableCellProps<T>) {
  const { column, columnIndex, rowData, rowIndex, rowSpan } = props;

  return (
    <td
      width={column.width}
      className={cn(
        'border-zinc-200 dark:border-coolgray-mid border-b',
        getCellClassName(props, 'cell')
      )}
      rowSpan={rowSpan}
      style={typeof column.width === 'number' ? { minWidth: column.width } : undefined}
    >
      {column.cellRenderer?.({
        rowData,
        column,
        columnIndex,
        rowIndex,
        rowSpan,
      }) ?? String((column.key ? rowData[column.key] : null) ?? '-')}
    </td>
  );
}

export const TableCell = memo(_TableCell) as typeof _TableCell;
