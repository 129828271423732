'use client';

import orderBy from 'lodash/orderBy';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TableColumn, TableContextType, TableProps, TableSort } from './types';

type TableProviderProps<T> = PropsWithChildren<TableProps<T>>;

const TableContext = createContext<TableContextType<any>>({} as TableContextType<any>);

export function useTableContext<T>() {
  const context = useContext(TableContext) as TableContextType<T>;

  if (!context) {
    throw new Error(
      'To use interactive features, please include the TableProvider outside the root of your Table'
    );
  }

  return context;
}

export function TableProvider<T>({
  children,
  data: rawData,
  columns,
  stickyHeader,
  allowHorizontalScroll,
  plugins,
  ...props
}: TableProviderProps<T>) {
  const [sort, setSort] = useState<TableSort<T> | null>(null);
  const toggleSort = useCallback((column: TableColumn<any>) => {
    setSort((prev) => {
      if (prev?.direction === 'desc') {
        return null;
      }

      return {
        column,
        direction: prev?.column === column ? 'desc' : 'asc',
      };
    });
  }, []);
  const ref = useRef<HTMLTableElement>(null);
  const data = useMemo(() => {
    return sort
      ? orderBy(rawData, sort.column.key ?? sort.column.sortIteratee, sort.direction)
      : rawData;
  }, [rawData, sort]);
  const stickyColumns = useMemo(() => columns.some((x) => x.sticky), [columns]);
  const value = useMemo(
    () => ({
      ...props,
      ref,
      allowHorizontalScroll,
      columns,
      data,
      sort,
      toggleSort,
      stickyColumns,
      stickyHeader: stickyHeader && !stickyColumns && !allowHorizontalScroll,
    }),
    [props, columns, data, sort, toggleSort, stickyColumns, stickyHeader, allowHorizontalScroll]
  );

  useEffect(() => {
    if (plugins && plugins.length > 0) {
      plugins.forEach((plugin) => plugin.setup(value));

      return () => {
        plugins.forEach((plugin) => plugin.teardown());
      };
    }
  }, [plugins, value]);

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}
