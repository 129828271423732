import classNames from 'classnames';
import { memo } from 'react';
import { TableHeaderCell } from './table-header-cell';
import { TableProps } from './types';
import { getHeaderCellClassName } from './util';

type TableHeaderProps<T> = Pick<
  TableProps<T>,
  | 'columns'
  | 'compact'
  | 'stickyHeader'
  | 'stickyLeftClassName'
  | 'stickyTopClassName'
  | 'expandable'
  | 'headerCellClassName'
  | 'headerClassName'
  | 'headerRowClassName'
  | 'subHeaderRowClassName'
>;

function _TableHeader<T>({
  headerClassName,
  headerRowClassName,
  headerCellClassName,
  subHeaderRowClassName,
  columns,
  compact,
  stickyHeader,
  stickyLeftClassName = 'left-0',
  stickyTopClassName = 'top-0',
  expandable,
}: TableHeaderProps<T>) {
  const hasSubHeaders = columns.some((x) => !!x.subHeader);

  return (
    <thead className={classNames('relative', headerClassName)}>
      <tr
        className={classNames(headerRowClassName, {
          'sticky z-20': stickyHeader,
          [stickyTopClassName]: stickyHeader,
        })}
      >
        {expandable && <th />}
        {columns.map((column, columnIndex) => {
          if (column.hidden) {
            return null;
          }

          return (
            <TableHeaderCell<T>
              key={(column.key as string) ?? columnIndex}
              headerCellClassName={headerCellClassName}
              column={column}
              columnIndex={columnIndex}
              compact={compact}
              isFirstColumn={columnIndex === 0}
              isLastColumn={columnIndex === columns.length - 1}
              stickyLeftClassName={stickyLeftClassName}
            />
          );
        })}
      </tr>
      {hasSubHeaders && (
        <tr className={subHeaderRowClassName}>
          {expandable && <th />}
          {columns.map((column, columnIndex) => {
            if (column.hidden) {
              return null;
            }

            return (
              <td
                key={(column.key as string) ?? columnIndex}
                className={getHeaderCellClassName(
                  {
                    column,
                    columnIndex,
                    compact,
                    isFirstColumn: columnIndex === 0,
                    isLastColumn: columnIndex === columns.length - 1,
                    headerCellClassName,
                    stickyLeftClassName,
                  },
                  'subHeader'
                )}
              >
                {column.subHeader ?? ''}
              </td>
            );
          })}
        </tr>
      )}
    </thead>
  );
}

export const TableHeader = memo(_TableHeader) as typeof _TableHeader;
