'use client';

import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';
import { TableBody } from './table-body';
import { TableHeader } from './table-header';
import { TableProvider, useTableContext } from './table-provider';
import { TableProps } from './types';

function InnerTable<T>() {
  const {
    ref,
    mode,
    compact,
    className,
    headerClassName,
    headerRowClassName,
    headerCellClassName,
    subHeaderRowClassName,
    bodyClassName,
    rowClassName,
    cellClassName,
    columns,
    data,
    rowKey,
    rowFilter,
    emptyMessage,
    emptyRowClassName,
    stickyHeader,
    stickyLeftClassName,
    stickyTopClassName,
    expandable,
    expandableData,
    expandedRowClassName,
    onRowMouseEnter,
    onRowMouseLeave,
    onRowClick,
  } = useTableContext<T>();

  return (
    <table
      ref={ref}
      className={classNames(className, {
        'table-auto': mode === 'auto',
        'table-fixed': mode === 'fixed',
        'table-compact': compact,
      })}
    >
      <TableHeader
        columns={columns}
        compact={compact}
        expandable={expandable}
        headerClassName={headerClassName}
        headerRowClassName={headerRowClassName}
        headerCellClassName={headerCellClassName}
        subHeaderRowClassName={subHeaderRowClassName}
        stickyHeader={stickyHeader}
        stickyLeftClassName={stickyLeftClassName}
        stickyTopClassName={stickyTopClassName}
      />
      <TableBody
        className={bodyClassName}
        columns={columns}
        compact={compact}
        data={data}
        emptyMessage={emptyMessage}
        emptyRowClassName={emptyRowClassName}
        rowKey={rowKey}
        rowClassName={rowClassName}
        rowFilter={rowFilter}
        cellClassName={cellClassName}
        stickyLeftClassName={stickyLeftClassName}
        expandable={expandable}
        expandableData={expandableData}
        expandedRowClassName={expandedRowClassName}
        onRowMouseEnter={onRowMouseEnter}
        onRowMouseLeave={onRowMouseLeave}
        onRowClick={onRowClick}
      />
    </table>
  );
}

function _Table<T extends Record<string, any>>({
  mode = 'auto',
  className = 'w-full',
  emptyMessage = 'No data available',
  stickyLeftClassName = 'left-0',
  stickyTopClassName = 'top-0',
  allowHorizontalScroll,
  ...restProps
}: TableProps<T>) {
  const tableContent = <InnerTable<T> />;

  return (
    <TableProvider<T>
      allowHorizontalScroll={allowHorizontalScroll}
      mode={mode}
      className={className}
      emptyMessage={emptyMessage}
      stickyLeftClassName={stickyLeftClassName}
      stickyTopClassName={stickyTopClassName}
      {...restProps}
    >
      {allowHorizontalScroll ? <TableViewport>{tableContent}</TableViewport> : tableContent}
    </TableProvider>
  );
}

const TableViewport: FC<PropsWithChildren> = ({ children }) => (
  <div className="w-full overflow-auto lg:overflow-unset">{children}</div>
);

export const Table = memo(_Table) as typeof _Table;
