import { FC } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import { LoaderHeightWidthProps, LoaderSizeProps } from 'react-spinners/helpers/props';

export const LoadingSpinner: FC<Omit<LoaderHeightWidthProps, 'color'>> = (props) => (
  <div className="p-4 lg:px-6" data-testid="loading-spinner">
    <BarLoader {...props} color="#002147" />
  </div>
);

export const LoadingSpinnerCircle: FC<Omit<LoaderSizeProps, 'color'>> = (props) => (
  <div className="w-full h-full flex items-center justify-center" data-testid="loading-spinner">
    <PuffLoader {...props} color="#002147" />
  </div>
);
